#AiGram_Footer {
  width: 100%;
  height: calc(var(--safe-area-bottom) + 3.125rem);
  border-top: 0.0625rem solid var(--color-borders);
  padding-top: 0.3125rem;
  padding-bottom: calc(var(--safe-area-bottom) + 0.3125rem);
  box-sizing: border-box;
  background-color: var(--color-background);

  display: flex;
  align-items: center;

  transition: background-color 200ms ease-in-out;

  &.aigram {
    background-color: #17191D;
    border-top-color: #2B2D36;

    .tab__item {
      color: #8e8e8e;
    }
  }

  .tab__item {
    position: relative;
    flex: 1;
    padding-top: 1.875rem;
    height: 100%;
    box-sizing: border-box;
    font-size: 0.625rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: var(--color-text-secondary);

    &.active {
      color: #007AFF;
    }

    &-icon {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }
}
