.event-header {
  height: var(--header-height);
  padding: 0.375rem 0.8125rem 0.5rem 0.8125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  background: #0E0E16;
  position: relative;
  color: #fff;

  @media (max-width: 600px) {
    padding: 0.5rem;
  }

  .sort-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

